<template>
      <!--====== APPIE HEADER PART START ======-->
    
    <header class="appie-header-area appie-header-2-area appie-sticky">
        <div class="container">
            <div class="header-nav-box">
                <div class="row align-items-center">
                    <div class="order-1 order-sm-1">
                        <div class="appie-logo-box">
                            <a href="#">
                                <img class="w-25" src="@/assets/images/logo-white.png" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    <!--====== APPIE HEADER PART ENDS ======-->
</template>

<script>
import NavItems from '../NavItems.vue'
export default {
     props:{
      menuItems:{
          type: Array,
          required: true,
      },
      nasted:{
            type:Boolean,
            default:true
        }  
    },
	components: { NavItems },
     mounted() {
        document.addEventListener('scroll', this.stickMenu)
    },
    methods:{
        showSidebar(e){
            this.$emit("toggleSidebar",e)
        },
        stickMenu(){
        const result = document.querySelector('.appie-sticky')
           if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                result.classList.add('sticky')
            } else {
                result.classList.remove('sticky')
            }
        }
    }

}
</script>

<style>

</style>