<template>
    <div>
        <Sidebar
            :sidebar="sidebar"
            @toggleSidebar="toggleSidebar"
            :menuItems="navs"
        />

        <Header @toggleSidebar="toggleSidebar" :menuItems="navs" />

        <Search />

        <ListClubs
            title="List Club"
        />

        <Footer />

        <div class="back-to-top back-to-top-2">
            <a href="#"><i class="fal fa-arrow-up text-white fa-lg"></i></a>
        </div>
    </div>
</template>

<script>
import Sidebar from "../layout/Sidebar.vue";
import Footer from "../layout/Footer.vue";
import Header from "../layout/Header.vue";
import ListClubs from "./ListClubs.vue";
import Search from "./Search.vue";

export default {
    components: {
        Header,
        ListClubs,
        Footer,
        Sidebar,
        Search
    },
    data() {
        return {
        sidebar: false,
        navs: [
            {
            name: "home",
            path: "/"
            },
            {
            name: "profile",
            path: "/profile",
            },
        ],
        };
    },
    mounted() {
        document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
        topToBottom() {
            const result = document.querySelector(".back-to-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
    },
};
</script>

<style>
</style>