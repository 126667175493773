import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from "axios";

import vuetify from '../src/plugins/vuetify'

import { store } from './store/index'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/custom-animated.css'
import '../src/assets/css/default.css'
import '../src/assets/css/magnific.dark.css'
import '../src/assets/css/style.css'
import '../src/assets/css/main.css'
import '../src/assets/css/global.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin

Vue.use(IconsPlugin)

Vue.config.productionTip = false

const encodedCredentials = btoa(`${process.env.VUE_APP_USERNAME}:${process.env.VUE_APP_PASSWORD}`);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = `Basic ${encodedCredentials}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
