<template>
    <div>
        <div class="appie-page-title-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="appie-hero-content-2">
                            <v-row class="pt-15" justify="center" dense>
                                <v-col cols="12" md="4">
                                    <v-autocomplete placeholder="Select Area" item-text="name" clearable solo rounded
                                    item-value="id" class="flex-full-width me-1 text-xs" v-model="form.area_id" @click="getArea"
                                    @change="updateArea" :items="list_area"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-autocomplete placeholder="Select Club" item-text="name" clearable solo rounded
                                    item-value="id" class="flex-full-width me-1 text-xs" v-model="form.club_id" @click="getClub"
                                    @change="updateClub" :items="list_club"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  

<script>
import axios from 'axios'
import Swal from "sweetalert2";

    export default {
        data() {
            return {
                list_club: [],
                list_area: [],
                form: {
                    club_id: null,
                    area_id: null
                },
            }
        },
        computed: {},
        mounted(){
            this.load();
        },    
        methods:{
            getClub() {
                const params = {
                    city_id: this.form.area_id,
                };
                axios.get('club', { params })
                    .then(res => {
                        this.list_club = res.data.data.reverse();
                    }).catch(error => {
                        Swal.fire({
                            html: error,
                            icon: "warning",
                            position: 'top-end',
                            toast: true,
                            timer: 2500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    });

            },
            getArea() {
                const params = {
                    city_id: "",
                };
                axios.get('city', { params })
                    .then(res => {
                        this.list_area = res.data.data;
                    }).catch(error => {
                        Swal.fire({
                            html: error,
                            icon: "warning",
                            position: 'top-end',
                            toast: true,
                            timer: 2500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    });

            },
            updateClub() {
                this.getClub()
            },
            updateArea() {
                this.getClub()
            },
            async load() {
                Promise.all([
                    await this.getClub(),
                    await this.getArea(),
                ]).then(function (results) {
                    results;
                });
            },
        }
    }
</script>