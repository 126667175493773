<template>
      <!--====== APPIE FOOTER PART START ======-->
    
    <section class="appie-footer-area">
        <div class="container">
            <div class="col-lg-12">
                <div class="footer-copyright d-flex align-items-center justify-content-end pt-35">
                    <div class="copyright-text">
                        <p>Copyright © 2024 FTL GYM. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>