<template>
    <Home/>
</template>

<script>
import Home from '../components/Home/index'
export default {
	components: { Home  },

}
</script>

<style>

</style>