<template>
    <!--====== APPIE SERVICE 2 PART START ======-->
  
    <section class="appie-services-2-area pb-50 pt-30" id="service">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="appie-section-title">
                    <h4 class="appie-title">{{ title }}</h4>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-one__single pricing-one__single_2 active">
                <v-card class="mx-auto" flat style="border-radius: 40px 0 0 0;">
                    <v-img :src="imgs" height="250px" width="100%"></v-img>
                </v-card>
                <div class="pricig-body">
                    <div class="mt-5">
                    <v-row justify="space-around">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2" fab dark color="orange" v-bind="attrs" v-on="on" 
                                @click.stop="dialog360 = true">
                                    <v-icon dark>mdi-rotate-360</v-icon>
                                </v-btn>
                            </template>
                            <span>360 view</span>
                        </v-tooltip>

                        <v-dialog
                            v-model="dialog360"
                            max-width="750px"
                            style="z-index: 1020;"
                            hide-overlay
                            persistent
                            @input="onDialogInput"
                        >
                            <v-card height="75vh">
                                <v-card-title class="border">
                                    <v-spacer></v-spacer>
                                    <v-icon size="18"
                                        class="rounded-full bg-gray-100/20 text-gray-800 hover:text-black hover:bg-gray-400"
                                        @click="closeDialog360">
                                        mdi-close
                                    </v-icon>
                                </v-card-title>

                                <iframe width="100%" height="100%" :src="modalUrl" frameborder="0" allow="autoplay" allowfullscreen></iframe>
                            </v-card>
                        </v-dialog>
    
                        <v-menu v-model="fasilitasMenu" top transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltipOn }">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="{ ...on, ...tooltipOn }"
                                >
                                    <v-icon dark>mdi-arm-flex</v-icon>
                                </v-btn>
                                </template>
                                <span>Fasilitas</span>
                            </v-tooltip>
                            </template>
                            <v-card class="p-1 semi-transparent-background rounded-border">
                            <v-list dense>
                                <v-list-item v-for="(item, index) in fasilitasItems" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title class="letter-spacing">
                                    <span class="dot"></span>{{ item }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            </v-card>
                        </v-menu>
    
                        <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2" fab dark color="green" v-bind="attrs" v-on="on" @click.stop="dialogGalery = true">
                            <v-icon dark>mdi-folder-multiple-image</v-icon>
                            </v-btn>
                        </template>
                        <span>Galeri</span>
                        </v-tooltip>

                        <v-dialog
                            v-model="dialogGalery"
                            max-width="750px"
                            style="z-index: 1020;"
                            hide-overlay
                            persistent
                        >
                            <v-card height="75vh">
                                <v-card-title class="border">
                                    <v-spacer></v-spacer>
                                    <v-icon size="18"
                                        class="rounded-full bg-gray-100/20 text-gray-800 hover:text-black hover:bg-gray-400"
                                        @click="closeDialogGalery">
                                        mdi-close
                                    </v-icon>
                                </v-card-title>

                                <v-carousel cycle
                                    height="452"
                                    hide-delimiter-background
                                    show-arrows-on-hover>
                                    <v-carousel-item
                                        v-for="(item,i) in itemsImg"
                                        :key="i"
                                        :src="item.src"
                                    ></v-carousel-item>
                                </v-carousel>
                            </v-card>
                        </v-dialog>
    
                        <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2" fab dark color="info" v-bind="attrs" v-on="on" @click="openMap">
                            <v-icon dark>mdi-map-search-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Map</span>
                        </v-tooltip>
                    </v-row>
                    </div>
                </div>
                </div>
            </div>
            </div> -->

            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="(club, index) in bgClub" :key="index">
                    <div class="pricing-one__single pricing-one__single_2 active">
                        <v-card class="mx-auto bg-transparent" flat>
                            <v-img :src="club.src" class="white--text align-end border-0 text-white">
                            <v-card-title class="font-[9pt] bgAlamat text-center align-center">Jl. Peta Barat No.72 RT.8/RW.12, Kalideres, Kec. Kalideres, Jakarta Barat, 15124</v-card-title></v-img>
                        </v-card>
                        <div class="pricig-body">
                            <div class="mt-5">
                                <v-row justify="space-around">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small class="mx-2" fab dark color="#003285" v-bind="attrs" v-on="on" 
                                            @click.stop="dialog360 = true">
                                                <v-icon size="22" dark>mdi-rotate-360</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>360 view</span>
                                    </v-tooltip>

                                    <v-dialog
                                        v-model="dialog360"
                                        max-width="750px"
                                        style="z-index: 1020;"
                                        hide-overlay
                                        persistent
                                        @input="onDialogInput"
                                    >
                                        <v-card height="75vh">
                                            <v-card-title class="border">
                                                <v-spacer></v-spacer>
                                                <v-icon size="18"
                                                    class="rounded-full bg-gray-100/20 text-gray-800 hover:text-black hover:bg-gray-400"
                                                    @click="closeDialog360">
                                                    mdi-close
                                                </v-icon>
                                            </v-card-title>

                                            <iframe width="100%" height="100%" :src="modalUrl" frameborder="0" allow="autoplay" allowfullscreen></iframe>
                                        </v-card>
                                    </v-dialog>

                                    <v-menu v-model="fasilitasMenu[index]" top transition="scale-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltipOn }">
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="#2A629A"
                                                v-bind="attrs"
                                                v-on="{ ...on, ...tooltipOn }"
                                                @click="openFacilityDialog(index)"
                                            >
                                                <v-icon small dark>mdi-arm-flex</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Fasilitas</span>
                                        </v-tooltip>
                                        </template>
                                        <v-card class="p-1 semi-transparent-background rounded-border">
                                        <v-list dense>
                                            <v-list-item v-for="(item, index) in fasilitasItems" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title class="letter-spacing">
                                                <span class="dot"></span>{{ item }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        </v-card>
                                    </v-menu>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-2" fab dark color="#FF7F3E" small v-bind="attrs" v-on="on" @click.stop="dialogGalery = true">
                                            <v-icon small dark>mdi-folder-multiple-image</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Galeri</span>
                                    </v-tooltip>

                                    <v-dialog
                                        v-model="dialogGalery"
                                        max-width="750px"
                                        style="z-index: 1020;"
                                        hide-overlay
                                        persistent
                                    >
                                        <v-card height="75vh">
                                            <v-card-title class="border">
                                                <v-spacer></v-spacer>
                                                <v-icon size="18"
                                                    class="rounded-full bg-gray-100/20 text-gray-800 hover:text-black hover:bg-gray-400"
                                                    @click="closeDialogGalery">
                                                    mdi-close
                                                </v-icon>
                                            </v-card-title>

                                            <v-carousel cycle
                                                height="452"
                                                hide-delimiter-background
                                                show-arrows-on-hover>
                                                <v-carousel-item
                                                    v-for="(item,i) in itemsImg"
                                                    :key="i"
                                                    :src="item.src"
                                                ></v-carousel-item>
                                            </v-carousel>
                                        </v-card>
                                    </v-dialog>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mx-2" fab dark color="#FFDA78" v-bind="attrs" v-on="on" @click="openMap" small>
                                            <v-icon small dark>mdi-map-search-outline</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Map</span>
                                    </v-tooltip>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    <!--====== APPIE SERVICE 2 PART ENDS ======-->
  </template>
  
  <script>

  import imgs from '../../assets/images/ftlgym.jpeg'
  import club01 from '@/assets/images/club/01.webp'
  import club02 from '@/assets/images/club/02.webp'
  import club03 from '@/assets/images/club/03.webp'
  import club04 from '@/assets/images/club/04.webp'
  import club05 from '@/assets/images/club/05.webp'
  import club06 from '@/assets/images/club/06.webp'
  import club07 from '@/assets/images/club/07.webp'
  import club08 from '@/assets/images/club/08.webp'
  import club09 from '@/assets/images/club/09.webp'
  import club10 from '@/assets/images/club/10.webp'
  import club11 from '@/assets/images/club/11.webp'
  import club12 from '@/assets/images/club/12.webp'
  import club13 from '@/assets/images/club/13.webp'
  import club14 from '@/assets/images/club/14.webp'
  import club15 from '@/assets/images/club/15.webp'
  import club16 from '@/assets/images/club/16.webp'
  import club17 from '@/assets/images/club/17.webp'
  import club18 from '@/assets/images/club/18.webp'
  import club19 from '@/assets/images/club/19.webp'
  import club20 from '@/assets/images/club/20.webp'
  import club21 from '@/assets/images/club/21.webp'

  export default {
    data: () => ({
        fasilitasMenu: [],
        dialog360: false,
        dialogGalery: false,
        fasilitasItems: ['Gym', 'Swimming Pool', 'Spa', 'Restaurant', 'Conference Room'],
        data: [
            { "club": "FTL TANJUNG DUREN", "alamat": "Jl. Tanjung Duren Raya No.21, Tanjung Duren Selatan, Grogol Petamburan, Jakarta Barat, DKI Jakarta 11470", "url": "https://360vpro.id/FtlGym_TanjungDuren" },
            { "club": "FTL CIPUTAT", "alamat": "Ciputat Indah Permai, Jl. Ir H. Juanda No.18, Pisangan, Ciputat Timur, Tangerang Selatan, Banten 15411", "url": "https://360vpro.id/FtlGym_Ciputat/" },
            { "club": "FTL RAWAMANGUN", "alamat": "Jl. Pemuda, RT.1/RW.2, Jati, Kec. Pulo Gadung, Jakarta Timur, DKI Jakarta 13220", "url": "https://360vpro.id/FtlGym_Rawamangun/" },
            { "club": "FTL PONDOK BAMBU", "alamat": "Jl. Pahlawan Revolusi No.11, RW.5, Klender, Kec. Duren Sawit, Jakarta Timur, DKI Jakarta 13430", "url": "https://360vpro.id/FtlGym_PondokBambu/" },
            { "club": "FTL TEBET", "alamat": "Jl. Tebet Utara Dalam No.19, RT.7/RW.2, Tebet Tim., Kec. Tebet, Jakarta Selatan, DKI Jakarta 12820", "url": "" },
            { "club": "FTL GRAND GALAXY", "alamat": "Jl. Pulo Sirih Utama, Jaka Setia, Kec. Bekasi Sel., Kota Bekasi, Jawa Barat 17147", "url": "https://360vpro.id/FtlGym_Galaxy/" },
            { "club": "FTL BEKASI", "alamat": "Jl. Insinyur H. Juanda No.91, RT.001/RW.004, Bekasi Jaya, Kec. Bekasi Tim., Kota Bekasi, Jawa Barat 17113", "url": "" },
            { "club": "FTL MENTENG", "alamat": "Jl. H. Agus Salim 67, RT.8/RW.4, Gondangdia, Kec. Menteng, Jakarta Pusat, DKI Jakarta 10350", "url": "https://360vpro.id/FtlGym_Menteng/" },
            { "club": "FTL GANDARIA", "alamat": "Jl. Gandaria 1 No.336, RW.2, Jakarta, DKI Jakarta 12130", "url": "https://360vpro.id/FtlGym_Gandaria/" },
            { "club": "FTL GREEN GARDEN", "alamat": "Komplek Perumahan Green Graden Blok A.7 No. 31-35, Kedoya Utara, Jakarta Barat, DKI Jakarta 11520", "url": "https://360vpro.id/FtlGym_GreenGarden/" },
            { "club": "FTL PONDOK GEDE", "alamat": "Jl. Raya Jati Makmur No.124, RT.004/RW.007, Jatimakmur, Kec. Pondok Gede, Kota Bekasi, Jawa Barat 17143", "url": "https://360vpro.id/FtlGym_PondokGede/" },
            { "club": "FTL CIREUNDEU", "alamat": "Jl. Raya Cirendeu, Cireundeu, Kec. Ciputat Timur, Tangerang Selatan, Banten 15419", "url": "https://360vpro.id/FtlGym_Cirendeu/" },
            { "club": "FTL DEPOK LAMA", "alamat": "Jl. Kartini No.14, Pancoran Mas, Kota Depok, Jawa Barat 16431", "url": "" },
            { "club": "FTL CIPONDOH", "alamat": "Jl. KH. Hasyim Ashari No.26, Buaran Indah, Kota Tangerang, Banten 15119", "url": "" },
            { "club": "FTL BENHIL", "alamat": "Jl. Bendungan Hilir No.120, 118, 116, Tanah Abang, Jakarta Pusat, DKI Jakarta 10210", "url": "https://360vpro.id/FtlGym_Benhil/" },
            { "club": "FTL GUNUNG SAHARI", "alamat": "Jl. Gn. Sahari No.14D, Pademangan Barat, Jakarta Utara, DKI Jakarta 14420", "url": "" }
        ],
        modalUrl: "https://360vpro.id/FtlGym_TanjungDuren",
        itemsImg: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
        imgs: imgs,
        bgClub: [
          { src: club01 },
          { src: club02 },
          { src: club03 },
          { src: club04 },
          { src: club05 },
          { src: club06 },
          { src: club07 },
          { src: club08 },
          { src: club09 },
          { src: club10 },
          { src: club11 },
          { src: club12 },
          { src: club13 },
          { src: club14 },
          { src: club15 },
          { src: club16 },
          { src: club17 },
          { src: club18 },
          { src: club19 },
          { src: club20 },
          { src: club21 }
        ]
    }),
    props: {
      title: {
        type: String,
      },
      description: {
        type: String,
      },
    },
    watch: {
        fasilitasMenu: {
            handler(newVal) {
                const openIndex = newVal.findIndex((val) => val);
                if (openIndex !== -1) {
                    this.fasilitasMenu = this.fasilitasMenu.map((val, index) => index === openIndex);
                }
            },
            deep: true,
        },
    },
    created() {
        this.fasilitasMenu = Array(this.bgClub.length).fill(false);
    },
    methods: {
        closeDialog360(){
            this.dialog360 = false
        },
        closeDialogGalery(){
            this.dialogGalery = false
        },
        onDialogInput(isOpen) {
            if (!isOpen) {
                this.stopVideo();
            }
        },
        stopVideo() {
            const iframe = this.$refs.videoIframe;
            if (iframe) {
                const iframeSrc = iframe.src;
                iframe.src = iframeSrc;
            }
        },
        openMap(){
            const mapUrl = 'https://maps.app.goo.gl/t3ns6XoSh6BqJdCYA';
            window.open(mapUrl, '_blank');
        },
        openFacilityDialog(index) {
            this.selectedFacilityIndex = index;
            this.fasilitasMenu = true;
        },
        closeFacilityDialog() {
            this.fasilitasMenu = false;
            this.selectedFacilityIndex = null;
        },
    }
  };
  </script>
  